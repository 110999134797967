import './blogcard.css';
import React from 'react';
import { Link } from 'react-router-dom';

// import image from  '../../media/blog-images/blog-card/unsplash_abs_1.png';

// //author images as avatars

// const avatar1 = "https://eu.ui-avatars.com/api/?name=Vikram+Singh&size=250";
// const avatar2 = "https://eu.ui-avatars.com/api/?name=Etaat+Karim&size=250";
// const avatar3 = "https://eu.ui-avatars.com/api/?name=Kumar+Anubhav&size=250";



// Dummy data
// const dummyData = {
//     imgURL: image,
//     imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
//     title: "Chatbot Revolution: Enhancing Customer Experiences",
//     overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
//     date: 'October 30, 2023',
//     authors: [
//         // { id: "01", name: 'Vikram Singh', image: avatar1 },
//         // { id: "02", name: 'Etaat Karim', image: avatar2 },
//         { name: 'Vikram Singh', image: avatar1 },
//         { name: 'Etaat Karim', image: avatar2 },
//         { name: 'Kumar Anubhav', image: avatar3 },
//       ],
//     categories: ['Conversational AI', 'Chatbot', 'Online Commerce']
//   };



//assuming that author profile is located using a value which is hyphen seperated firt, middle and last name in small cases. 

//use props when done testing with dummyData
// const BlogCard = ({ imgURL, imgAltText, title, overview, date, authors, categories}) => {
    const BlogCard = ({ imgURL, imgAltText, title, overview, date, authors, categories}) => {

    // const { imgURL, imgAltText, title, overview, date, authors, categories} = dummyData;

    return (
      <div className="blogcard-container">
        <div className="blogcard-image">
          <Link to={`/blog/${title.replace(/\s+/g, '-')}`}>
              <img src={imgURL} alt={imgAltText} />
          </Link>
        </div>

        <div className="blogcard-categories">
            {categories.slice(0, 2).map((category, index) => (
              // {categories.map((category, index) => (
                <Link key={index} to={`/category/${category.replace(/\s+/g, '-')}`}>
                      <button>{category}</button>
                </Link>
          ))}
        </div>
  
        <div className="blogcard-container-minus-img">
        <div className="blogcard-title">
            <Link to={`/blog/${title.replace(/\s+/g, '-')}`}>
                <h1>{title}</h1>
            </Link>
        </div>
        
        {/* <p>{overview}</p> */}
        <div className="blogcard-overview">
            <Link to={`/blog/${title.replace(/\s+/g, '-')}`}>
              <p>{overview.length > 100 ? `${overview.substring(0, 100)}...` : overview}</p>
            </Link>  
        </div>
  
        <div className="blogcard-authors">

            {authors.length === 1 ? (
              <div className="blogcard-authors-detail" key={authors[0].name}>
                <div className="blogcard-author-image">
                    <Link  to={`/author/${authors[0].name.replace(/\s+/g, '-')}`}> 
                          <img src={authors[0].image} alt={authors[0].name} />  
                    </Link>
                </div>
                
                <div className="blogcard-author-name">
                  <Link to={`/author/${authors[0].name.replace(/\s+/g, '-')}`}>
                  {/* <span>Authors: </span> */}
                    <p>{authors[0].name}</p>
                  </Link>
                </div>
              </div>
            ) : (
                <div className="blogcard-authors-detail" key={authors[0].name}>
                    <div className="blogcard-author-image">
                      <Link  to={`/author/${authors[0].name.replace(/\s+/g, '-')}`}> 
                            <img src={authors[0].image} alt={authors[0].name} />  
                      </Link>
                   </div>

                   <div className="blogcard-author-name">
                      <Link to={`/author/${authors[0].name.replace(/\s+/g, '-')}`}>
                      {/* <span>Authors: </span> */}
                        <p>{authors[0].name} et al...</p>
                      </Link>
                  </div>
                </div>
            )}
        </div>

        <div className="blogcard-date">
           {/* <span>Published: </span> */}
            <p>{date}</p>
        </div>
        {/* Add a button named read more */}
        <div className="blogcard-readarticle">
            <Link to={`/blog/${title.replace(/\s+/g, '-')}`}>
              <button>Continue Reading...</button>
            </Link>
        </div>
        </div>
      </div>
    );
  };
  

export default BlogCard




//To display max two authors, if present, both with their image and name
// {authors.slice(0, 2).map((author, index) => (
//   <div className="blogcard-authors-detail" key={index}>
//       <Link  to={`/author/${author.name.replace(/\s+/g, '-')}`}> 
//          {/* <img src={author.image} alt={author.name} /> */}/
//          <span>{author.name}</span>
//          <span className="dot">&#8226;</span>
//          <p>{author.name} &nbsp;&nbsp;</p>
//       </Link>
//   </div>
// ))}

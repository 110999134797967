import React from 'react';
import './home.css';
import { Hero, CTA} from '../../components';
// import { ConvAI, Solutions, Technologies, BlogSectionPaginate} from '../../containers';
import { ConvAI, Solutions, Technologies} from '../../containers';

const Home = () => {
  return (
    <div className='home'>
        <Hero/>
        <ConvAI/>
        <Solutions/>
        <Technologies/>
        {/* <BlogSectionPaginate/> */}
        <CTA/>
    </div>
  )
}

export default Home
import './contactform.css';
import React, {useEffect} from 'react';


function ContactForm() { 

    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      document.body.appendChild(script);
  }, []);

  return (
        <div className='contact-form '>

            <div className='contact-title gradient-text'>
                <h1>Get in touch</h1>
            </div>

            <div className="alternate-contact-info">
              <p>Send us an email at <a href='mailto:connect@zestifai.com'>connect@zestifai.com</a> or use the widget below to book a meeting.</p>
            </div>

            <div className="calendly-inline-widget" data-url="https://calendly.com/vikrampsingh/30min?background_color=f9f9f9&text_color=2a2a2a&primary_color=2375FF"></div>

        </div>
  )
} 

export default ContactForm

import React from 'react';
import './cookiepolicy.css';
import { Link } from 'react-router-dom';

const CookiePolicy = () => {
  return (
    <div className='cookie-policy-container'>
      
      <h1 className='gradient-text'>Cookie Policy</h1>

      <h3>Introduction</h3>
        <p>This Cookie Policy explains how Zestifai Technologies Pvt. Ltd. ("we," "us," or "our") uses cookies and similar technologies on our website at <b>www.zestifai.com</b>("Website").</p>
        <br/>
      <h3>What Are Cookies?</h3>
        <p> Cookies are small text files that a website stores on your device when you visit the site. They are widely used to remember information about your preferences and activity, so you don't have to keep re-entering them whenever you come back to the site or browse from one page to another.</p>
        <br/>
      <h3>Cookies We Use</h3>
      <p>We use two kinds of Cookies:</p>
      <br/>
        <p><b>Necessary Cookies:</b> These cookies are essential for the basic functionality of our Website, such as enabling you to navigate properly and access secure areas. We use these cookies to identify you as a unique user and remember your preferences (e.g. language choice).</p>
        <br/>
        <p><b>Analytics Cookies:</b> These cookies allow us to collect non-personally identifiable information about your use of our Website, such as the pages you visit, the time you spend on each page, and the website that referred you. We use this information to improve the user experience and performance of our Website.</p>
        <br/>

        <p><b>Third-Party Cookies: </b>Our Website uses the Calendly widget for appointment booking. This widget offers its own cookie controls to help you accept, reject and manage your cookies. Please refer to <Link to='https://calendly.com/privacy' target='blank'><b>Calendly's Privacy Policy</b></Link> for more information on its cookie usage</p>
        <br/>
      <h3> Your Cookie Choices</h3>
        <p>You can control or disable cookies through your browser settings or through accept, reject option given to you on our cookie banner. Please note that, this may limit your ability to use certain features of our Website.</p>
        <br/>
      <h3> Contact Us</h3>
        <p>If you have any questions about this Cookie Policy, please contact us at <Link to='mailto:connect@zestifai.com'><b>connect@zestifai.com</b></Link></p>
        <br/>
    </div>
  )
}

export default CookiePolicy
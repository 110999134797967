import React from 'react';
import './cta.css';
import { Link } from 'react-router-dom';



const CTA = () => {
  return (
    <div className="cta-container">
      <div className='cta'>
        <div className='cta-title'>
            <h2>The future of interaction is here, and it's conversational AI.</h2>
        </div>
        <div className='cta-btn'>
          <button type='button'>
            <Link to='/contact'>Get in touch</Link>
          </button>
        </div>
    </div>
    </div>
  )

}

export default CTA
import React from 'react';
import './solutions.css';
import {Feature} from '../../components';


const solutionsData =[
  {
    title: "AI-Powered E-commerce Virtual Assistants",
    description: "Leverage our cutting-edge conversational AI technology to provide personalized and efficient virtual assistants for your e-commerce platform. Enhance customer experience, provide real-time support, and drive sales through tailored product recommendations and interactive conversational interfaces."
  },
  {
    title: "Intelligent Customer Support Chatbots",
    description: "Transform your customer support operations with our AI-driven chatbots that can handle customer queries, provide instant assistance, and offer personalized solutions round the clock. Utilize natural language understanding and generative AI to deliver seamless and empathetic customer support experiences, enhancing customer satisfaction and loyalty."
  },
  // {
  //   title: "Custom AI Solutions for Enhanced User Engagement",
  //   description: "Harness the power of our tailored conversational AI and generative AI solutions to create engaging user experiences across various digital platforms. From interactive chatbots to dynamic content generation, we offer customizable AI solutions that drive user engagement, improve retention, and foster meaningful interactions, helping your business stand out in the competitive digital landscape."
  // }
]

const Solutions = () => {
  return (
    <div className="offered-solutions-container" id='offered-solutions'>
      <div className='offered-solutions-title gradient-text'>
        <h1>Our Solutions</h1>
      </div>
      <div className='offered-solutions-cards'>
          {
            solutionsData.map((solution, index) => (
              <div key={index}>
                <Feature title={solution.title} description={solution.description}/>
              </div>
            ))
          }
      </div>
    </div>
  )
}

export default Solutions
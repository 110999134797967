import './bloglanding.css';
import React from 'react';
import { BlogHeroCard } from '../../components';  
import {BlogCategoryPage} from '../../containers';



const BlogLanding = () => {
  return (
    <div className='blog-landing-page-container'>
        <h1 className='gradient-text'>Our blog space</h1>

        <div className="blog-featured-article-section">
           <BlogHeroCard />
        </div>
        <div className="blog-article-browse-section">
           <BlogCategoryPage />
        </div>

    </div>
  )
}

export default BlogLanding
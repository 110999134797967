import './blogarticlepage.css';
import React from 'react'
import {Link } from 'react-router-dom';
import { RelatedArticles } from '../../components';
import article from './dummyArticleData.json';
import blogImage from "../../media/blog-images/blog-card/unsplash_abs_1.png";

// import { FaAngleDoubleRight } from "react-icons/fa";


const BlogArticlePage = () => {
  
  return (
    
    <div className='blog-article-container'>
      <div className='blog-article-head-section'>
          <div className="blog-article-image">
            <img src={blogImage} alt={article.imgAltText} />
          </div>

          <div className="blog-article-categories">
                {article.categories.map((category, index) => (
                    <Link key={index} to={`/category/${category.replace(/\s+/g, '-')}`}>
                          <button>{category}</button>
                    </Link>
                ))}
          </div>

          <div className="blog-article-title">
              <h1>{article.title}</h1>
          </div>

        
          
          <div className="blog-article-overview">
              <p>{article.overview}</p>
          </div>

          

          <div className="blog-article-date">
              <p>Date Published: {article.date}</p>  
          </div>

         <div className="blog-article-section-header">
            <h2>Sections in this article</h2>
         </div>

          <div className="blog-article-sections">
                {article.body.map((item, index) => (
                  <div key={index} className='blog-article-sections-button'>
                    {/* <div className='blog-article-sections-icon'><FaAngleDoubleRight/></div> */}
                    {/* <div> */}
                      <button onClick={() => {
                            // const section = document.querySelector(`#section-${item.section.replace(/\s+/g, '-')}`);
                            const section = document.querySelector(`#section-${index + 1}`);
                            section.scrollIntoView({ behavior: 'smooth' });
                            }}>
                          <h2>{item.section}</h2>
                      </button>
                    {/* </div> */}
                  </div>
                ))}
          </div>

      </div>

      <div className='blog-article-body-section'>
          {article.body.map((item, index) => (
          <div key={index} id={`section-${index + 1}`} style={{ scrollMarginTop: '5rem' }}>                                           
          {/* <div key={index} id={`section-${item.section.replace(/\s+/g, '-')}`} style={{ scrollMarginTop: '5rem' }}>                                            */}
              <h2>{item.section}</h2>
              <p>{item.sectionBody}</p> 
              <br />  
          </div>
          ))}
   
      </div>

        <div className="blog-article-authors">
            {article.authors.map((author, index) => (
                <div className="blog-authors-detail" key={index}>
                    <div className="blog-author-image">
                        <Link  to={`/author/${author.name.replace(/\s+/g, '-')}`}> 
                            <img src={author.image} alt={author.name} />  
                        </Link>
                    </div>
                    <div className="blog-author-name">
                        <Link  to={`/author/${author.name.replace(/\s+/g, '-')}`}> 
                          <p>{author.name}</p>
                        </Link>
                    </div>
                </div>
            ))}
        </div>
        <RelatedArticles />

      </div>
  );
}

export default BlogArticlePage
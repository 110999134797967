import './company.css';
import React from 'react';

const Company = () => {
  return (
   <div className="company-container">
        <h1 className='gradient-text'> Our Mission</h1>
        <h1 className='gradient-text'> Our Values</h1>

   </div>
  )
}

export default Company
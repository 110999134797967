import React from 'react';
import './privacypolicy.css';
import { Link } from 'react-router-dom';


const PrivacyPolicy = () => {
  return (
    <div className='privacy-policy-container'>

      <h1 className='gradient-text'>Privacy Policy</h1>
      <h3>Introduction</h3>
        <p>This Privacy Policy describes how Zestifai Technologies Pvt. Ltd. ("we," "us," or "our") collects, uses, and discloses your personal information when you visit our website at <b>www.zestifai.com</b>("Website").</p>
        <br/>
      <h3>Information We Collect</h3>
      <p>We collect two types of information on our Website:</p>
      <br/>
        <p><b>Basic Browsing Data (Optional):</b> With your consent, we may collect non-personally identifiable information about your use of our Website, such as browser type, operating system, pages visited, and time spent on each page. This information is used to improve the user experience and for analytics purposes.</p>
        <br/>
        <p><b>Appointment Data:</b> When you book an meeting through our Calendly widget, you will be directed to an appointment widget provided by <Link to='https://calendly.com/' target='blank'><b>Calendly</b></Link>, where you will be required to provide your name and email address. This information is collected and stored by Calendly, and we use it only to confirm your appointment booking and follow up with you to discuss your specific needs and service requirements. We do not store any data collected through the Calendly widget on our servers. Please refer to Calendly's <Link to='https://calendly.com/privacy' target='blank'><b>Privacy Policy</b></Link> and <Link to='https://calendly.com/terms' target='blank'><b>Terms of Use</b></Link> for more information on how it handles the data it collects. </p>
      <br/>
      <h3>Sharing Your Information</h3>
        <p>We do not share your personal information with any third parties except for Calendly, which processes your appointment booking data. We may also share your information if required by law or to protect our rights and interests.</p>
        <br/>
      <h3>Data Security</h3>
      <p>We take reasonable precautions to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure, so we cannot guarantee the security of your information.</p>
      <br/>
      <h3>Your Choices</h3>
      <p>You have the following choices regarding your personal information:</p>
      <br/>
      <p><b>Cookies:</b> You can choose to disable cookies in your browser settings. However, this may affect your ability to use certain features of our Website.</p>
      <br/>
      <p><b>Appointment Data:</b> You can manage your appointment booking data by contacting Calendly directly.</p>
      <br/>
      <h3>Changes to This Policy</h3>
      <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Website.</p>
      <br/>
      <h3>Contact Us</h3>
      <p>If you have any questions about this Privacy Policy, please contact us at <Link to='mailto:connect@zestifai.com'><b>connect@zestifai.com</b></Link></p>

      








   
    </div>
  )
}

export default PrivacyPolicy
import './cookiebanner.css';
import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';


const Cookie = () => {
    const [hasConsented, setHasConsented] = useState(false);
    
    const onAccept = () =>{
        setHasConsented(true);
        console.log("Cookie accepted");

    }
    const onDecline = () =>{
        setHasConsented(true); 
        console.log("Cookie declined");
        
    }

    return (
        hasConsented? null: (
        <div className='cookie-banner'>
            <CookieConsent 
                style={{backgroundColor: "#170F49", color: "#FFFFFF", textAlign:"left", fontFamily:"var(--font-family)", fontSize:"12px"}}
                cookieName='zestifai-cookie'
                location='bottom'
                buttonText="I Accept"
                buttonStyle={{backgroundColor: "#2375FF", color: "#FFFFFF", fontFamily:"var(--font-family)", fontSize:"12px", borderRadius:"5px"}}
                hideOnAccept='true'
                hideOnDecline='true'
                enableDeclineButton='true'
                declineButtonText='I Decline'
                declineButtonStyle={{backgroundColor:"#9F9F9F", color: "#FFFFFF", fontFamily:"var(--font-family)", fontSize:"12px", borderRadius:"5px"}}
                onAccept={onAccept}
                onDecline={onDecline}
                expires={7}
                >
                <p>Zestifai uses cookies to enhance your browsing experience, serve personalised content, and analyze our traffic. Read our <Link to='/cookie-policy'>Cookie Policy</Link><span>, </span><Link to='/privacy-policy'>Privacy Policy</Link></p> 
            </CookieConsent>
        </div>
    )
    )
}

export default Cookie
import React from 'react';
import './footer.css';
import full_logo from '../../media/zestifai-logos/zestifai_logo_name.svg';
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <div className='footer'>
           <div className="footer-top">
               <div className="footer-company">
                  <Link to='/'>
                    <img src={full_logo} alt="Zestifai Technologies" />
                  </Link>
                  
                  <p><a href='mailto:connect@zestifai.com'>connect@zestifai.com</a></p>
                </div>
                <div className='footer-links-container'>
                  {/* Following divs to be opened when respective detailed page is ready] */}
                  <div className="footer-links">
                      {/* <p><Link to='/blog'>Blog</Link></p>
                      <p><Link to='/solutions'>Solutions</Link></p>
                      <p><Link to='/company'>Company</Link></p>  */}
                  </div> 
                  <div className="footer-notices">
                      <p><Link to='/terms-of-service'>Terms of Service</Link></p>
                      <p><Link to='/privacy-policy'>Privacy Policy</Link></p>   
                      <p><Link to='/cookie-policy'>Cookie Policy</Link></p>
                      
                      
                  </div>
                </div>
          </div>
          <div className="footer-bottom">
              <div><p>Copyright © 2024 Zestifai Technologies Pvt. Ltd. &nbsp;</p></div>
              <div><p>All rights reserved.</p></div>  
          </div>
    </div>

)};

export default Footer
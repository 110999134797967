import React, {useState, useEffect, useRef}from 'react';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';
import full_logo from '../../media/zestifai-logos/zestifai_logo_name.svg';
import './navbar.css';
import {Link} from 'react-router-dom';

//Following menu items to be enabled once relevent pages are ready
const Menu = () => (
  <>
    {/* <p><Link to='/solutions'>Solutions</Link></p>
    <p><Link to='/blog'>Blog</Link></p>
    <p><Link to='/company'>Company</Link></p> */}
  </>  
)
 
const Navbar = () => {
  const[toggleMenu, setToggleMenu] = useState(false);
  const menuRef = useRef();

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setToggleMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='navbar' id='navbar'>
      <div className='navbar-links'>
        <div className='navbar-links-logo'>
          <Link to='/'>
            <img src={full_logo} alt='logo'/>
          </Link>
        </div>
        {/* To be included when respective independent pages are ready */}
        <div className='navbar-links-menu'>
          <Menu/>
        </div>
      </div>
      <div className="navbar-cta">
        <button type="button">
          <Link to='/contact'><p>Get in touch</p></Link>
        </button>
      </div>
      
      <div className="navbar-folded-menu" ref={menuRef}>
        {toggleMenu
          ? <RiCloseLine color="var(--color-primary)"  size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="var(--color-primary)"  size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="navbar-folded-menu-container scale-up-center">
          <div className="navbar-folded-menu-container-links">
            {/* To be enabled once relevent pages are ready */}
            <Menu/>
          </div>
          <div className="navbar-folded-menu-container-cta">
              <button type='button'>
                  <Link to='/contact'><p>Get in touch</p></Link>
              </button>
          </div>
        </div>
        )}
      </div>
    </div>
  )
};

export default Navbar
import React from 'react';
import './technologypartners.css';
import {aws, openai, huggingface} from './imports.js';
import { Link } from 'react-router-dom';

const Technologies = () => {
  return (
    <div className='technologies'>
        <div className='technology-header gradient-text'>
            <h1>We work with technologies you can trust</h1>
        </div>
        <div className='technologies-container'>
            <div className='huggingface-container'>
                <Link to='https://huggingface.co/' target='_blank' ><img src={huggingface} alt="hugging face"id="huggingface-image"/></Link>
            </div>
            <div className='openai-container'>
                <Link to='https://openai.com/'  target='_blank'><img src={openai} alt="open ai" id="openai-image"/></Link>
            </div>
            
            <div className='aws-container'>
                <Link to='https://aws.amazon.com/'  target='_blank'><img src={aws} alt="aws" id="aws-image"/></Link>
            </div>
            
        </div>
    </div>
  )
}

export default Technologies
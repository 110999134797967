import './relatedarticles.css';
import { Link } from 'react-router-dom';
// import React, {useState} from 'react';
import React from 'react';
import { BlogCard} from '../../components';


import image1 from  '../../media/blog-images/blog-card/unsplash_tech_1.png';
import image2 from  '../../media/blog-images/blog-card/unsplash_tech_2.png';
import image3 from  '../../media/blog-images/blog-card/unsplash_tech_3.png';



// //author images as avatars

const avatar1 = "https://eu.ui-avatars.com/api/?name=Vikram+Singh&size=250";
const avatar2 = "https://eu.ui-avatars.com/api/?name=Etaat+Karim&size=250";
const avatar3 = "https://eu.ui-avatars.com/api/?name=Kumar+Anubhav&size=250";



// // Dummy data
const blogData = [
  {
    imgURL: image1,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 }
      ],
    categories: ['Online Commerce']
  },
  {
    imgURL: image2,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Kumar Anubhav', image: avatar3 },
        { name: 'Vikram Singh', image: avatar1 },
        
      ],
    categories: ['Conversational AI', 'Online Commerce']
  },
  {
    imgURL: image3,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 },
        { name: 'Kumar Anubhav', image: avatar3 },
      ],
    categories: ['Conversational AI', 'Chatbot', 'Online Commerce']
  },
  {
    imgURL: image1,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 }
      ],
    categories: ['Online Commerce']
  },
  {
    imgURL: image2,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 }
      ],
    categories: ['Conversational AI', 'Online Commerce']
  },
  {
    imgURL: image3,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 }
      ],
    categories: ['Conversational AI', 'Chatbot', 'Online Commerce']
  }

]

const RelatedArticles = () => {
const itemsPerPage = 3; 
// const [currentPage, setCurrentPage] = useState(1);
const currentPage = 1;

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = blogData.slice(indexOfFirstItem, indexOfLastItem);

// const totalPages = Math.ceil(blogData.length / itemsPerPage);   


  return (
    <div className='related-articles-container'>
        <div className="related-articles-top-panel">
            <div className="related-articles-title">
                <h1>Related Articles</h1>
            </div>
            <div className="ralated-articles-browseall">
                <Link to="/articles">
                    <button>Browse All</button> 
                </Link>
            </div>
        </div>

        <div className="related-blogcards">

            {currentItems.map((blog, index) => (
                <div key={index}>
                    <BlogCard
                    imgURL={blog.imgURL}
                    imgAltText={blog.imgAltText}
                    title={blog.title}
                    overview={blog.overview}
                    date={blog.date}
                    authors={blog.authors}
                    categories={blog.categories}
                    />
                </div>
            ))}
        </div>
        

    </div>
  )
}

export default RelatedArticles

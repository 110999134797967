import React from 'react';
import './feature.css';

const Feature = ({title, description}) => {
  return (
    <div className='feature-container'>
        <div className='feature-container-title'>
            <div/>
            <h1>{title}</h1>
        </div>
        <div className='feature-container-description'>
            <p>{description}</p>
        </div>
        
    </div>
  )
}

export default Feature
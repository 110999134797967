import React from 'react';
import './solutionspage.css';



const SolutionsPage = () => {
  return (
    <div className='solutions-page-container'>
        <h1 className='gradient-text'>Solutions</h1>
    </div>
  )
}

export default SolutionsPage
import React from 'react';
import './convaifeatures.css';
import { Feature } from '../../components';


const featuresData = [
  {
    title: "Conversational User Experience ",
    description: "Conversational AI allows businesses to create natural, context-aware, and personalised user experiences. Users can interact with businesses in a way that is more natural and engaging."
  },
  {
    title: "Always-On User Engagement",
    description: "Conversational AI solutions are always available, 24/7/365. Businesses can provide their customers and employees with support and information whenever they need it."
  },
  {
    title: "Enterprise Grade and User Friendly",
    description: "Conversational AI solutions are enterprise grade and user friendly. The solutions are secure, scalable, and comply with all relevant policies and regulations. Users can get the information and support they need more quickly and easily."
  }
]

const ConvAIFeatures = () => {
  return (
    <div className='convai' id='convai'>
      <div className='convai-title gradient-text'>
        <div><h1>Why Conversational AI</h1></div>
      </div>
      <div className='convai-features'>
        {
          featuresData.map((feature, index) => (
            <div key={index}>
              <Feature title={feature.title} description={feature.description}/>
            </div>
          ))
        } 
      </div> 
    </div>
  )
}

export default ConvAIFeatures;
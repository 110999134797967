import './blogherocard.css';
import React from 'react';
import { Link } from 'react-router-dom';

import image1 from  '../../media/blog-images/blog-card/unsplash_abs_1.png';
// import image2 from  '../../media/blog-images/blog-card/unslash_abs_2.png';
// import image3 from  '../../media/blog-images/blog-card/unsplash_abs_3.png';

const avatar1 = "https://eu.ui-avatars.com/api/?name=Vikram+Singh&size=250";
const avatar2 = "https://eu.ui-avatars.com/api/?name=Etaat+Karim&size=250";
// const avatar3 = "https://eu.ui-avatars.com/api/?name=Kumar+Anubhav&size=250";

// Dummy Blog Data
const blogData = {
    imgURL: image1,
    imgAltText: 'Phasellus condimentum turpis non nibh finibus posuere.',
    title: "Phasellus condimentum turpis non nibh finibus posuere.",
    overview: 'Duis vel ante pulvinar, commodo erat id, condimentum ipsum. Etiam sit amet pulvinar quam. Vestibulum elementum bibendum tempor. Vestibulum finibus vestibulum finibus. Sed blandit tempor lectus eu semper. Nullam ullamcorper finibus erat, in malesuada leo mattis nec.',
    date: 'October 30, 2023',
    authors: [
        { name: 'Vikram Singh', image: avatar1 },
        { name: 'Etaat Karim', image: avatar2 }
      ],
    categories: ['consectetur adipiscing', 'massa', 'fringilla mauris']
 }


//use props when done testing with dummy blog data
// const BlogCard = ({ imgURL, imgAltText, title, overview, date, authors, categories}) => {
const BlogHeroCard = () => {

    // const { imgURL, imgAltText, title, overview, date, authors, categories} = blogData;

  return (
    <div className="blog-hero-container">
        <Link to={`/blog/${blogData.title.replace(/\s+/g, '-')}`}>
                <div className="blog-hero-image" style={{ backgroundImage: `url(${image1})`}}>
                
                    <div className="blog-hero-body">
                            <div className="blog-hero-title">
                                <h3>Featured Article</h3>  
                                <h1>{blogData.title}</h1>
                            </div>

                            <div className="blog-hero-overview">
                                <p>{blogData.overview.length > 150 ? `${blogData.overview.substring(0, 150)}...` : blogData.overview}</p>
                            </div>    

                            <div className="blog-hero-date">
                                <p>{blogData.date}</p>  
                            </div>
                                
                            <div className="blog-hero-authors">
                                {blogData.authors.map((author, index) => (
                                    <div className="blog-hero-authors-detail" key={index}>
                                        <div className="blog-hero-author-image">
                                            <Link  to={`/author/${author.name.replace(/\s+/g, '-')}`}> 
                                                <img src={author.image} alt={author.name} />  
                                            </Link>
                                        </div>
                                        <div className="blog-author-hero-name">
                                            <Link  to={`/author/${author.name.replace(/\s+/g, '-')}`}> 
                                            <p>{author.name}</p>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            
                    </div>
                </div>

        </Link>
    </div>
  )
}

export default BlogHeroCard
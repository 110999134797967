//Actual Application

import React, {useEffect} from 'react';
import './app.css';
import { Navbar, Footer, ContactForm, CookieBanner, PageNotFound} from './components';
import {PrivacyPolicy, CookiePolicy, TermsOfService} from './components';
import { Home, Company, SolutionsPage, BlogCategoryPage, BlogArticlePage, BlogLanding } from './containers';
import { Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
 

const App = () => {

  const location = useLocation();

  // Scrolls the window to the top on route change
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location]);

  return (
    <div className="app">
      <Navbar />
        <div className="content">
          <Routes>

            <Route path="/" element={<Home/>} />
            <Route path="/contact" element={<ContactForm />} />
            <Route path="/company" element={<Company />} />
            <Route path="/solutions" element={<SolutionsPage />} />
            <Route path="/blog" element={<BlogLanding/>} />

            <Route path="/blog/Chatbot-Revolution:-Enhancing-Customer-Experiences" element={<BlogArticlePage/>} />
            <Route path="/category/Conversational-AI" element={<BlogCategoryPage/>} />

            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />

            <Route path="*" element={<PageNotFound />} />
            
          </Routes>
        </div>
        <CookieBanner/>
      <Footer />
    </div>
  );
};
export default App


//-----------------------------------------------------------


//For testing individual components

// import React from 'react';
// import './app.css';
// import { Navbar } from './components';
// import {Footer} from './components';

// import { ContactForm, ZestifaiLaunchTeaser } from './components';
// import FetchCountries from './testing/fetchcountries/FetchCountries';
// import Select from './testing/select/Select';
// import { Technologies } from './containers';
// import { ContactForm } from './components';
// import {CTA} from './components';
// import {ConvAI} from './containers';
// import {Feature} from './components';
// import {Solutions} from './containers';
// import { BlogCard} from './components';
// import {BlogSectionPaginate} from './containers';
// import {BlogArticlePage} from './containers';
// import { RelatedArticles} from './components';
// import {BlogCategoryPage} from './containers';
// import { BlogSectionSlider } from './containers';
// import { BlogHeroCard } from './components';
// import {PageNotFound} from './components';
// import {Home} from './containers';

// const App = () => {
//   return (
//    <div className="app">
//       <div className="content">
//         <Navbar/>
//             <ContactForm/>
//         <Footer/>
//       </div>
//   </div>


//   )}
// export default App

//-----------------------------------------------------------

//Zestifai Launch Teaser Testing

// import React from 'react';
// import './app.css';
// import { ZestifaiLaunchTeaser } from './components';


// const App = () => {
//   return (
//    <div className="app">
//       <div className="content">
//             <ZestifaiLaunchTeaser/>
//       </div>
//   </div>


//   )}
// export default App


//-----------------------------------------------------------

//For Zestifai Old Website

// import React from 'react';
// import './app.css';
// import { ZestifaiOld } from './components';


// const App = () => {
//   return (
//    <div className="app">
//       <div className="content">
//             <ZestifaiOld/>
//       </div>
//   </div>


//   )}
// export default App
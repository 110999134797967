import React from 'react';
import './termsofservice.css';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className='terms-of-service-container'>

      <h1 className='gradient-text'>Terms of Service</h1>

      <h3>Introduction</h3>
      <p>Thank you for visiting the website of <b>Zestifai Technologies Pvt. Ltd.</b> This document outlines the Terms of Service ("Terms") governing your use of our website and services. By accessing or using this website, you agree to be bound by these Terms.</p>
      <br/>

    <h3>Website Usage</h3>
    <p>This website provides information about <b>Zestifai Technologies Pvt. Ltd.</b>, its services and solutions. You are granted a non-exclusive, non-transferable license to use the website for informational purposes only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, or otherwise exploit any content on this website without our prior written consent.</p>
    <br/>
    <h3>Disclaimer of Warranties</h3>
    <p>The information on this website is provided "as is" without warranties of any kind, express or implied, including but not limited to warranties of accuracy, completeness, merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that the website will be error-free or uninterrupted.</p>
    <br/>

    <h3>Intellectual Property Rights</h3>
    <p>All content on this website, including text, graphics, logos, images, and software, is the property of <b>Zestifai Technologies Pvt. Ltd.</b> or its licensors and is protected by copyright, trademark, and other applicable laws. You may not use any of this content without our prior written consent.</p>
    <br/>
    <h3>Limitation of Liability</h3>
    <p>We shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of this website or its content.</p>
    <br/>

    <h3>Governing Law and Dispute Resolution</h3>
    <p>These Terms shall be governed by and construed in accordance with the laws of the State of Karnataka in India. Any dispute arising out of or relating to these Terms shall be subject to the exclusive jurisdiction of the Courts of Karnataka, India, specifically the courts located in the Bangalore city. </p>
    <br/>

    <h3>Changes to Terms of Service</h3>
    <p>We may modify these Terms at any time by posting the revised terms on this website. Your continued use of this website after such modifications constitutes your acceptance of the revised Terms.</p>
    <br/>

    <h3>Contact Us</h3>
    <p>If you have any questions about these Terms, please contact us at <Link to='mailto:connect@zestifai.com'><b>connect@zestifai.com</b></Link></p>


    </div>
  )
}

export default TermsOfService
import './blogsectionslider.css';
import React, { useRef }from 'react';
import { BlogCard } from '../../components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';




import image1 from  '../../media/blog-images/blog-card/unsplash_tech_1.png';
import image2 from  '../../media/blog-images/blog-card/unsplash_tech_2.png';
import image3 from  '../../media/blog-images/blog-card/unsplash_tech_3.png';



// //author images as avatars

const avatar1 = "https://eu.ui-avatars.com/api/?name=Vikram+Singh&size=250";
const avatar2 = "https://eu.ui-avatars.com/api/?name=Etaat+Karim&size=250";
const avatar3 = "https://eu.ui-avatars.com/api/?name=Kumar+Anubhav&size=250";



// // Dummy data
const blogData = [
  {
    imgURL: image1,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 }
      ],
    categories: ['Online Commerce']
  },
  {
    imgURL: image2,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Kumar Anubhav', image: avatar3 },
        { name: 'Vikram Singh', image: avatar1 },
        
      ],
    categories: ['Conversational AI', 'Online Commerce']
  },
  {
    imgURL: image3,
    imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
    title: "Chatbot Revolution: Enhancing Customer Experiences",
    overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
    date: 'October 30, 2023',
    authors: [
        // { id: "01", name: 'Vikram Singh', image: avatar1 },
        // { id: "02", name: 'Etaat Karim', image: avatar2 },
        { name: 'Etaat Karim', image: avatar2 },
        { name: 'Vikram Singh', image: avatar1 },
        { name: 'Kumar Anubhav', image: avatar3 },
      ],
    categories: ['Conversational AI', 'Chatbot', 'Online Commerce']
  },
  // {
  //   imgURL: image1,
  //   imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
  //   title: "Chatbot Revolution: Enhancing Customer Experiences",
  //   overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
  //   date: 'October 30, 2023',
  //   authors: [
  //       // { id: "01", name: 'Vikram Singh', image: avatar1 },
  //       // { id: "02", name: 'Etaat Karim', image: avatar2 },
  //       { name: 'Vikram Singh', image: avatar1 }
  //     ],
  //   categories: ['Online Commerce']
  // },
  // {
  //   imgURL: image2,
  //   imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
  //   title: "Chatbot Revolution: Enhancing Customer Experiences",
  //   overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
  //   date: 'October 30, 2023',
  //   authors: [
  //       // { id: "01", name: 'Vikram Singh', image: avatar1 },
  //       // { id: "02", name: 'Etaat Karim', image: avatar2 },
  //       { name: 'Vikram Singh', image: avatar1 }
  //     ],
  //   categories: ['Conversational AI', 'Online Commerce']
  // },
  // {
  //   imgURL: image3,
  //   imgAltText: 'Chatbot-Revolution-Enhancing-Customer-Experiences',
  //   title: "Chatbot Revolution: Enhancing Customer Experiences",
  //   overview: 'In the rapidly evolving landscape of customer service and engagement, chatbots have emerged as the catalysts of a profound revolution. "Chatbot Revolution: Enhancing Customer Experiences" delves into the dynamic world of conversational AI and its pivotal role in reshaping how businesses interact with their customers. From personalized interactions to seamless support, this blog explores the transformative impact of chatbots on enhancing customer experiences.',
  //   date: 'October 30, 2023',
  //   authors: [
  //       // { id: "01", name: 'Vikram Singh', image: avatar1 },
  //       // { id: "02", name: 'Etaat Karim', image: avatar2 },
  //       { name: 'Vikram Singh', image: avatar1 }
  //     ],
  //   categories: ['Conversational AI', 'Chatbot', 'Online Commerce']
  // }

]

const BlogSection = () => {

  const sliderRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Set the number of blog cards to show at once
    // slidesToScroll: 1,
    // centerMode: true,
    centerPadding: "50px",
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true
        }
      },
      // {
      //   breakpoint: 414,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1
      //   }
      // }
    ]


  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <div className='blog-section-container gradient-bg-2'>
      <div className='blog-section-title gradient-text'>
        <h1>Our blog space; uncovering trends for you</h1>
      </div>
      <div className="blog-navigate-all">
            <Link to={"/blog"}>
              <button className='blog-navigate-all-button'>
                    Browse All
              </button>
           </Link>
        </div>
      <div className='blog-section-cards'>
           <Slider ref={sliderRef} {...settings}>
                {blogData.map((blog, index) => (
                        <div key={index}>
                        <BlogCard
                        imgURL={blog.imgURL}
                        imgAltText={blog.imgAltText}
                        title={blog.title}
                        overview={blog.overview}
                        date={blog.date}
                        authors={blog.authors}
                        categories={blog.categories}
                        />
                      </div>
                  ))}
          </Slider>
      </div>
      <div className='blog-navigation-buttons'>
       
        <div className="blog-next-prev-buttons">
          <button className="blog-navigate-next" onClick={handlePrev}>
              <FaArrowLeft /> 
          </button>
          <button className="blog-navigate-previous"onClick={handleNext}>
              <FaArrowRight /> 
          </button>
        </div>
      </div>
    </div>
  )
}

export default BlogSection





//   var settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1
//   };
//   return (
//     <Slider {...settings}>
//       <div>
//         <h3>1</h3>
//       </div>
//       <div>
//         <h3>2</h3>
//       </div>
//       <div>
//         <h3>3</h3>
//       </div>
//       <div>
//         <h3>4</h3>
//       </div>
//       <div>
//         <h3>5</h3>
//       </div>
//       <div>
//         <h3>6</h3>
//       </div>
//     </Slider>
//   );
import React from 'react';
import './hero.css';
import hero_img from '../../media/hero-images/team_up-600-400.png';
import { Link } from 'react-router-dom';


const Hero = () => {
    return (
        <div className="hero gradient-bg-1">
            <div className="hero-content">
                <h1 className="gradient-text">
                    Talk to your tech, like you would with a friend.
                </h1>
                <p className="hero-content-description">
                    We build conversational AI powered solutions that help people connect with tech systems in a more natural and engaging way.
                </p>
                <div className="hero-content-action">
                    <div className='main-action'>
                        <button type='button'>
                            <Link to='/contact'>Get in touch</Link>
                        </button>
                    </div>
                    {/* Following div to be opened when independent Solutions page is ready */}
                    {/* <div className='sub-action'>
                        <button type='button'>
                            <a href='#solutions'>Our Solutions</a>
                        </button>
                    </div> */}
                </div>
            </div>
            <div className='hero-image'>
                <img src={hero_img} alt="virtual-assistant" />
            </div>
        </div>
        )
    }

export default Hero;

import './pagenotfound.css';
import React from 'react';
import { Link } from 'react-router-dom';




const PageNotFound = () => {
  return (
    <div className="pagenotfound-container">
        <div className="notound-message">
            <h1 className='gradient-text' >Page not found</h1>
        </div>

        <div className='back-home-link'>
            <span>Please check the URL and try again or &nbsp;</span><Link to='/'><button>Go Back Home</button></Link>
        </div>
    </div>

  )
}

export default PageNotFound
import './zestifailaunchteaser.css';
import React, {useState, useEffect} from 'react'
import zestifai_logo from '../../media/zestifai-logos/zestifai_logo_name.svg';

const ZestifaiLaunchTeaser = () => {

const [email, setEmail] = useState('');
const [inpError, setInpError] = useState('');
const [submitSuccess, setSubmitSuccess] = useState(false);

const resetSubmitMessages = () => {
  setEmail('');
  setInpError('');
  setSubmitSuccess(false);
};

useEffect(() => {
  if (inpError || submitSuccess) {
    const timer = setTimeout(resetSubmitMessages, 3000); // Resetting after 3 seconds

    return () => clearTimeout(timer); // Clearing the timer on unmount to avoid memory leaks
  }
}, [inpError, submitSuccess]);


const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

// const handleChange = (e) => {
//   setEmail(e.taget.value)
// };
const handleChange = (e) => {
  const { value } = e.target;
  setEmail(value);
};

const handleValidation = () => {
  if (!emailPattern.test(email)) {
    setInpError('Whoops! Please enter a valid email address');
  } else {
    setInpError('');
  }
};

const submitFormData = async (email_add, uri) => {
  try {
      const response = await fetch(uri, {
          method: 'POST',
          headers: { "Content-type": "application/json; charset=UTF-8" },
          body: JSON.stringify({email: email_add})
      });

      if (!response.ok) {
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      return { data, error: null };

  } catch (error) {
      return { data: null, error: error.message };
  }
};


const handleSubmit = async (e) => {
  e.preventDefault();

  if(!email){
    setInpError('Whoops! Please enter a valid email address');
  }else{
    handleValidation();

    if (!inpError) {
      const uri = "http://localhost:4000/subscribers";
      const { data, error } = await submitFormData(email, uri);
     
     if (error) {
        console.log(error);
      } else {
        console.log("Dumping data after successful submission");
        console.log(data);  
        setSubmitSuccess(true);
       
      }

    } 
  }
  
}

  return (
    <div className="teaser-container ">
        <div className="teaser-message">
          <h1 className='gradient-text'>reBuilding</h1>
          {/* <h1 className='gradient-text'>reLaunching</h1> */}
        </div>
        
        <div className="company-logo">
          <img src= {zestifai_logo} alt="Zestifai Technologies Logo" />
        </div>

        <div className="teaser-message-title">
            <h1>Making interactions with tech more human and fun...</h1>
        </div>

        <div className="notify-input-container">
            <input 
              type="email" 
              name="email"
              id='email'
              value={email}
              autoComplete='email'
              onChange={handleChange}
              onBlur={handleValidation}
              placeholder='Notify me when you launch' 
              tabIndex={1}
            />
            <button type='submit' onClick={handleSubmit} tabIndex={2}>Notify Me</button> 
        </div>
        {inpError && (<span className='inp-error'>{inpError}</span>)}
        {submitSuccess && (<span className='inp-success' role="img" aria-label="emoji">Thank you for your interest. 🎉 We'll keep you posted!</span>)}

        <div className="bottom-container">

          <div className='email-container'>
              <p><span>Get in touch: &nbsp;</span><a href='mailto:connect@zestifai.com'>connect@zestifai.com</a></p>
          </div>

          <div className="footer-bottom">
              
              <div><p>Copyright © 2023 Zestifai Technologies Pvt. Ltd. &nbsp;</p></div>
              <div><p>All rights reserved. &nbsp;</p></div>  
              
          </div>
        </div>
    </div>
  )
}

export default ZestifaiLaunchTeaser